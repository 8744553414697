import { IAppState, IPage } from 'vev';
import { extendAppState } from '../core/state';

const DOUBLE_SLASH = /\/{2,}/g;
const TRAILING_SLASH = /\/$/;
const LEADING_SLASH = /^\//;

const loadedPages: { [pageKey: string]: boolean } = {};
const pageFetchPromises: { [url: string]: Promise<PageLoad> } = {};
const headers: { [path: string]: PageHeader } = {};
// Save away headers for current page
savePageHead();

type PageHeader = {
  title: string;
  meta: NodeListOf<HTMLMetaElement>;
};

type PageLoad = {
  /** Pre-rendered page contents (vevroot innerHTML) */
  html: string;
  /** State for the given page */
  state: IAppState;
  /** Dependencies for the give page, vev.js is excluded */
  scripts: string[];
};

export function getBaseDir(fromPath: string, pageKey: string, pages: IPage[]): string {
  const page = pages.find((p) => p.key === pageKey);
  const dir = fromPath.replace(new RegExp(page?.path + '/?(index.html)?$'), '');
  return dir.replace(/(^\/|\/$)/g, '');
}

export function pageKeyByPath(path = '', pages: IPage[], dir?: string): string {
  path = path.replace(new RegExp('^\\/?' + cleanPath(dir || '')), '');
  path = removeUnnecessarySlashes(cleanPath(path));

  const indexPage = pages.find((page) => page.index) || pages[0];
  if (!path || path === '/' || path === cleanPath(dir || '')) return indexPage.key;
  for (const page of pages) {
    if (path.replace(/^\//, '') === page.key) return page.key;
    // Skip index page because it's the fallback if no match
    if (page.index) continue;
    // Finding start index in path for the page path (may be none (-1)), also checking if path is sub string of path
    const startIndex = path.indexOf(page.path);
    const pagePath = removeUnnecessarySlashes(page.path || '');
    // If page path is substring
    // then we check if they're a perfect match  extracting substring of the path
    if (startIndex !== -1 && path === pagePath) {
      return page.key;
    }
  }
  return indexPage.key;
}

const removeUnnecessarySlashes = (path: string) => path.replace(/(^\/)|(\/$)/g, '');

export const join = (...path: string[]) => {
  let res = '/' + path[0].replace(LEADING_SLASH, '').replace(TRAILING_SLASH, '');
  for (let i = 1; i < path.length; i++) {
    res += '/' + path[i].replace(LEADING_SLASH, '').replace(TRAILING_SLASH, '');
  }
  res = res.replace(TRAILING_SLASH, '');
  if (!res.endsWith('.html')) res += '/';
  return res;
  // return (path.join('/') + '/').replace(DOUBLE_SLASH, '/');
};

export function cleanPath(path: string | undefined) {
  return !path
    ? ''
    : path
        // remove starting .
        .replace(/^\./, '')
        // Remove query >azløkjbhvgcx>Zogfd8 or hash
        .replace(/(\?|#).*$/, '')
        // Remove double slash
        .replace(DOUBLE_SLASH, '/')
        // Remove trailing slash (but not if it's starting slash (path = "/"))
        .replace(/(?!^\/)\/$/, '')
        .replace(/index.html$/i, '');
}

/**
 *  Get page path by key
 */
export function pagePathByKey(pageKey: string, pages: IPage[], dir?: string): string {
  const page = pages.find((page) => page.key === pageKey);
  return page ? cleanPath(join('/', cleanPath(dir), page.path || '')) : '';
}

export async function loadPage(pageKey: string, state: IAppState): Promise<void> {
  if (state.editor || loadedPages[pageKey] || state.models.find((s) => s.key === pageKey)) return;
  loadedPages[pageKey] = true;

  const { host, dir } = state;
  let path = (host || dir || '').replace(/\/+$/, '') + pagePathByKey(pageKey, state.pages);
  if (!path.startsWith('/')) path = '/' + path;
  const { state: pageState } = await fetchPage(path);
  // // Storing the head in page content (used in page component to replace head when page becomes active)
  // const page = pageState.models.find((p) => p.key === pageKey);
  // if (page) page.content = { head };

  if (pageState) extendAppState(pageState);
}

export async function fetchPage(path: string): Promise<PageLoad> {
  if (!path.endsWith('/')) path += '/';
  if (!pageFetchPromises[path]) {
    pageFetchPromises[path] = fetch(path).then(async (res) => {
      const html = await res.text();
      const el = document.createElement('div');

      el.innerHTML = html;
      const root = el.querySelector('vevroot');
      const style = el.querySelector('.vev-style');
      const contentString = el.querySelector('script[type="text/vev"]')?.textContent;
      const scripts: string[] = [];
      const depEls = el.querySelectorAll('.vev-dep');
      // Store away the headers in
      savePageHead(path, el);

      for (let i = 0; i < depEls.length; i++) {
        const node = depEls.item(i);
        const src = isScript(node) && node.getAttribute('src');
        if (src && !src.endsWith('/vev.js')) scripts.push(src);
      }

      if (style) document.body.appendChild(style);

      return {
        html: root?.innerHTML || '',
        state: contentString ? JSON.parse(decodeURIComponent(contentString)) : null,
        scripts,
      };
    });
  }

  return pageFetchPromises[path];
}

/** Stores the needed headers away in the header path map  */
function savePageHead(path: string = location.pathname, extractFrom: HTMLElement = document.head) {
  headers[cleanPath(path)] = {
    title: extractFrom.querySelector('title')?.innerText || '',
    meta: extractFrom.querySelectorAll('meta'),
  };
}

/**
 * Apply headers for the given path
 * Looks for headers in the head path map object
 */
export function updatePageHeader(path: string = location.pathname) {
  const head = headers[cleanPath(path)];
  if (head) {
    document.title = head.title;
    // Query meta to remove
    const currentMeta = document.head.querySelectorAll('meta');
    // Insert new meta tags
    for (let i = 0; i < head.meta.length; i++) document.head.appendChild(head.meta.item(i));
    // Remove old meta tags
    for (let i = 0; i < currentMeta.length; i++) currentMeta.item(i).remove();
  }
}

function isScript(el: Element): el is HTMLScriptElement {
  return el.tagName === 'SCRIPT';
}
